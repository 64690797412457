<template>
  <div class="data-box">
    <van-nav-bar
        title="派餐地点"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-body">
      <div class="title-div">
        当前派餐：{{["早餐","午餐","晚餐","宵夜"][(sendMealsInfo.type-2)]}}
      </div>
      <div class="body-div" v-for="(item,index) in sendMealsList" :key="index">
        <div class="address-title">
          {{"领餐点"+ (index + 1)}}
        </div>
        <div class="address-div">
          <div class="address-div-item">
            <div class="item-key">派餐位置</div>
            <div class="item-value">{{ item.idResidueSite }}</div>
          </div>
          <div class="address-div-item">
            <div class="item-key">派餐人员</div>
            <div class="item-value">{{ item.personInCharge ? item.personInCharge.split(',')[0] : "无" }}</div>
            <div class="item-icon">
              <a :href="'tel:' + item.personInCharge.split(',')[1]" class="iconfont icon-dianhua1" v-show="item.personInCharge"></a>
            </div>
          </div>
          <div class="address-div-item">
            <div class="item-key">位置图片</div>
            <div class="item-image">
              <van-image class="image" fit="cover" round v-for="(image1,index1) in item.addressList" :key="index1" :src="image1" @click="showImage(item.addressList,index1)"/>
            </div>
          </div>
          <div class="address-div-item">
            <div class="item-key">餐食图片</div>
            <div class="item-image">
              <van-image class="image" fit="cover" round v-for="(image2,index2) in item.mealsList" :key="index2" :src="image2" @click="showImage(item.mealsList,index2)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {ImagePreview} from "vant";
import {
  personFoodArrange
} from "@/api/index";

export default {
  name: "parMeals_address",
  data() {
    return {
      sendMealsList: [],
      sendMealsInfo: {},
      itemList: [
        { image: require("../../assets/image/index_check.svg"), text: "我的对接人" },
        { image: require("../../assets/image/index_live.svg"), text: "住宿安排", router: "parLive" },
        { image: require("../../assets/image/index_material.svg"), text: "物资安排", router: "parMaterial" },
        { image: require("../../assets/image/index_meals.svg"), text: "用餐安排", router: "parMeals" },
        { image: require("../../assets/image/index_consump.svg"), text: "车辆能耗", router: "parConsumption" },
        { image: require("../../assets/image/index_clear.svg"), text: "车辆清洁", router: "parClear" }
      ],
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.getMealsInfo();
    },
    getMealsInfo(){
      let that = this;
      let params = {
        ayId: that.$store.getters.getOPDetails.id
      }
      personFoodArrange(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.sendMealsList = res.data.sendMealInfo ? res.data.sendMealInfo : [];
          that.sendMealsInfo = that.sendMealsList[0];
          that.sendMealsList.forEach(item => {
            item.addressList = item.picture1.split(",");
            item.mealsList = item.picture2.split(",");
          })
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    showImage(array,index){
      ImagePreview({
        images: array,
        startPosition: index,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.title-div{
  width: 100%;
  text-align: center;
  color: #3476FF;
  font-size: 16px;
}
.address-title{
  width: 100%;
  margin-top: 6px;
  color: #3E4576;
  font-size: 16px;
  font-weight: bold;
}
.address-div{
  width: 100%;
  .address-div-item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 16px;
    .item-key{
      width: 28%;
      color: #8B8FAD;
      font-size: 14px;
    }
    .item-value{
      width: 60%;
      color: #3E4576;
      font-size: 14px;
    }
    .item-icon{
      width: 12%;
      font-size: 14px;
      text-align: right;
      a{
        color: #3476FF;
      }
    }
    .item-image{
      width: 72%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .image{
        width: 64px;
        height: 64px;
        margin: 4px 3% 4px 0px;
        border-radius: 10px;
      }
    }
  }
}
</style>